import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import FadeInWrapper from '../components/animated/fade-wrapper';
import Layout from "../components/layout/page-layout";
import SocialLinks from '../components/social-links';
import { ContentfulPage } from '../types/graphql-types';

/**
 * data from graphql query
 */
interface Props {
  data: {
    contentfulPage: ContentfulPage;
  };
}

/**
 * Creates a page from a ContentfulPage object.
 * @param {Props} props  props from graphql query
 * @returns {TSX.Element} page
 */
const Home: React.FC<Props> = (props: Props): JSX.Element => {
  const page: ContentfulPage = props.data.contentfulPage;
  return (
    <Layout title={page.title as string} description={page.description?.description as string} image={page.headerImage?.publicUrl as string}>
      <div className="page row">
        <div className="col">
          <FadeInWrapper>
            <GatsbyImage className="img-fluid img-rounded" image={page.headerImage?.gatsbyImageData} alt={`${page.headerImage?.description}`} />
          </FadeInWrapper>
        </div>
        <div className="col col-4 pl-5">
          <FadeInWrapper>
            <h2>{page.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: page.body?.childMarkdownRemark.html }} />
            <SocialLinks />
          </FadeInWrapper>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query ($contentful_id: String!) {
  contentfulPage(contentful_id: { eq: $contentful_id }) {
    contentful_id
    title
    headerImage {
      gatsbyImageData
      publicUrl
    }
    description {
      description
    }
    body {
      childMarkdownRemark {
        html
      }
    }
  }
}
`;

export default Home;
